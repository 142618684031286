import React from 'react';
import SlideBS from '../Components/SlideBS';
import HomeProduct from '../Components/HomeProduct';
import HomeAbout from '../Components/HomeAbout';
import HomeService from '../Components/HomeService';
import HomeCategory from '../Components/HomeCategory';

function Home(props) {
  return (
    <>
      <SlideBS datas={props.datas.slides} />
      <HomeProduct topic="สินค้าขายดี" datas={props.datas.bestseller} handleChange={props.handleChange} updateCart={props.updateCart} />
      <HomeCategory datas={props.datas.categories} />
      {/* <HomeProduct topic="สินค้าแนะนำ" datas={props.datas.recommends} handleChange={props.handleChange} /> */}
      <HomeAbout datas={props.datas.about_home} />
      <HomeService datas={props.datas.services} />
    </>
  )
}

Home.defaultProps = {
  datas: {
    slides: [],
    categories: [],
    recommends: [],
    about_home: {},
    services: [],
    bestseller: []
  }
}

export default Home