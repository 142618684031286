import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import { MdPhoneInTalk } from 'react-icons/md';
import Logo from '../Images/logo/logo.png';
import { FaEnvelope } from 'react-icons/fa';

const Navbar = ({ datas }) => {
  if (!datas || !datas.contact || !datas.cart) {
    // return <></>;
  }

  const logoutHandler = () => {
    console.log("logoutHandler");

    var url = urlApi + '/?module=profile&call=logout';
    console.log("logoutHandler :", url);

    fetch(url, {
      method: 'POST'
    })
      .then(res => res.json())
      .then(result => {
        console.log("logoutHandler Result", result);

        if (result.returnStatus.code === "0000") {
          window.location.reload();
        }
      });
  }

  return (
    <>
      <header className="header-area header-wrapper">
        <div className="header-top-bar plr-185">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 d-none d-md-block">
                <div className="call-us">
                  <p className="mb-0">
                    <a href={'tel:' + datas?.contact?.phone}>
                      <MdPhoneInTalk className="mr-3" /> {datas?.contact?.phone}
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-right">
                <div className="call-us">
                  <p className="mb-0">
                    <a href={'mailto:' + datas?.contact?.email}>
                      <FaEnvelope className='mr-3' /> {datas?.contact?.email}
                    </a>
                  </p>
                </div>
              </div>
              {/* {datas.webuser === '' ?
                <div className="col-lg-6 col-md-6">
                  <p className='mb-0 nav-login'>
                    กรุณา <Link to="/Profile">เข้าสู่ระบบ</Link> เพื่อรับโปรโมชั่นพิเศษ
                  </p>
                </div>
                :
                <div className="col-lg-6 col-md-6">
                  <p className='mb-0 nav-login'>
                    ยินดีต้อนรับ <Link to="/Profile">{datas.webuser}</Link>
                    <Button style={{marginLeft: '1rem', backgroundColor: 'transparent', border: '1px solid #FFF'}} onClick={logoutHandler}>ออกจากระบบ</Button>
                  </p>
                </div>
              } */}
            </div>
          </div>
        </div>
        <div id="sticky-header" className="header-middle-area plr-185">
          <div className="container-fluid">
            <div className="full-width-mega-dropdown">
              <div className="row">
                <div className="col-lg-2 col-md-4">
                  <div className="logo">
                    <Link to="/">
                      <img src={Logo} alt="main logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-10 d-none d-lg-block">
                  <nav id="primary-menu">
                    <ul className="main-menu text-right">
                      <li>
                        <Link to="/">หน้าแรก</Link>
                      </li>
                      <li>
                        <Link to="/Category">ผลิตภัณฑ์</Link>
                      </li>
                      <li>
                        <Link to="/About">เกี่ยวกับเรา</Link>
                      </li>
                      {/* <li>
                        <Link to="/Service">บริการของเรา</Link>
                      </li> */}
                      {/* <li>
                        <Link to="/Payment">แจ้งชำระเงิน</Link>
                      </li> */}
                      <li>
                        <Link to="/Contact">ติดต่อเรา</Link>
                      </li>
                    </ul>
                  </nav>
                </div>

                {/* <div className="col-lg-2 col-md-8">
                  <div className="search-top-cart  f-right">

                    <div className="header-search f-left">
                      <div className="header-search-inner">
                        <button className="search-toggle">
                          <i className="zmdi zmdi-search"></i>
                        </button>
                        <form action="#">
                          <div className="top-search-box">
                            <input type="text" placeholder="Search here your product..." />
                            <button type="submit">
                              <i className="zmdi zmdi-search"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="total-cart f-left">
                      <div className="total-cart-in">
                        <div className="cart-toggler">
                          <Link to="/CartCheckout">
                            <span className="cart-quantity">{datas.cart.length}</span><br />
                            <span className="cart-icon">
                              <i className="zmdi zmdi-shopping-cart-plus"></i>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>

                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-menu-area d-block d-lg-none section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mobile-menu">
                <nav id="dropdown">
                  <ul>
                    <li>
                      <Link to="/">หน้าแรก</Link>
                    </li>
                    <li>
                      <Link to="/Product">ผลิตภัณฑ์</Link>
                    </li>
                    <li>
                      <Link to="/About">เกี่ยวกับเรา</Link>
                    </li>
                    {/* <li>
                      <Link to="/Service">บริการของเรา</Link>
                    </li>
                    <li>
                      <Link to="/Payment">แจ้งชำระเงิน</Link>
                    </li> */}
                    <li>
                      <Link to="/Contact">ติดต่อเรา</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Navbar.defaultProps = {
  datas: {
    webuser: '',
    contact: {
      phone: ''
    },
    cart: []
  }
}

export default Navbar